import { useStaffAndVesselCodeMaintenanceTracked } from "presentation/store/StaffAndVesselCode/StaffAndVesselCodeMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import StaffAndVesselCodeMaintenance from "./StaffAndVesselCodeMaintenance";
import { StaffAndVesselCodeMaintenanceFormPanel } from "./StaffAndVesselCodeMaintenanceFormPanel";
export const StaffAndVesselCodeMasterView: React.FC = () => { 
    const [staffAndVesselCodeState] = useStaffAndVesselCodeMaintenanceTracked(); 
    const { isAdd, isEditable } = staffAndVesselCodeState.masterState; 
    return <> 
        <div className="main-comp-wrapper im-hide-side-form-draggable"> 
            <StaffAndVesselCodeMaintenance/> 
            { 
                ((isAdd || isEditable) &&  
                <SliderPanel 
                    isOpen={false} 
                    leftSectionWidth={"100%"} 
                    rightSectionWidth={"0%"} 
                    leftChildren={<StaffAndVesselCodeMaintenanceFormPanel/>} 
                /> 
                ) 
            } 
        </div> 
    </> 
} 
