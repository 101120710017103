import { useStaffAndVesselCodeMaintenanceVM } from "presentation/hook/StaffAndVesselCode/useStaffAndVesselCodeMaintenanceVM";
import { useStaffAndVesselCodeMaintenanceTracked } from "presentation/store/StaffAndVesselCode/StaffAndVesselCodeMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import StaffAndVesselCodeTablePanel from "./StaffAndVesselCodeTablePanel";
const StaffAndVesselCodeMaintenance:React.FC = () => { 
    const [isLoading, setIsLoading] = useState(false); 
    const [staffAndVesselCodeState] = useStaffAndVesselCodeMaintenanceTracked(); 
    const staffAndVesselCodeVM = useStaffAndVesselCodeMaintenanceVM(); 
    const {isBackMaster} = staffAndVesselCodeState; 

    useEffect(() => { 
        const initialScreen = async() => {    
            setIsLoading(true); 
            try {      
                const results = await Promise.allSettled([ 
                    staffAndVesselCodeVM.loadDropdownOption(), 
                ]); 
                results.forEach((result, index) => { 
                    if (index === 0 && result.status === 'fulfilled') { 
 
                    }  
                }) 
            } catch(error) { 
                setIsLoading(false); 
            } 
        } 
        initialScreen().then(async () => {   
            staffAndVesselCodeVM.onSearch().then((data) => {             
            setIsLoading(false) 
        }).catch(error => {             
            setIsLoading(false) 
        }); 
            setIsLoading(false) 
        }).catch(error => {             
            setIsLoading(false) 
        }); 

    }, [isBackMaster,staffAndVesselCodeVM]) 

    if (isLoading) return <Loader Indicator="Stripe" size="Large" />; 

    return <> 
        <div className={`main-comp-wrapper im-hide-side-form-draggable`}> 
            <SliderPanel 
                isOpen={false} 
                draggable={false} 
                leftSectionWidth={"0%"} 
                rightSectionWidth={"100%"} 
                leftChildren={<StaffAndVesselCodeTablePanel/>} 
                /> 
        </div> 
    </> 
} 
export default memo(StaffAndVesselCodeMaintenance); 
