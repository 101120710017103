import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { StaffAndVesselCodeEntity } from "domain/entity/StaffAndVesselCode/StaffAndVesselCodeEntity";
import { axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { StaffAndVesselCodeRepository } from "./StaffAndVesselCodeRepo";
/** 
 * For template 
 * @returns  
 */ 
export const StaffAndVesselCodeRepoImpl = (): StaffAndVesselCodeRepository => { 
    const url = '/v1/staffAndVesselCode'; 
    const getEntities = async() : Promise<StaffAndVesselCodeEntity[]> => { 
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => { 
            return res.data 
        }).catch(err => { 
            return []; 
        }); 

    } 
    const getEntityByKey = async(key: string): Promise<StaffAndVesselCodeEntity> => { 
        return await axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => { 
            return res.data; 
        }).catch(err => { 
            return null; 
        }); 
    } 
    const createEntity = async(newData: StaffAndVesselCodeEntity) : Promise<ResponseEntity> => { 
        return await axiosPostData(referenceAxiosInstance, `${url}`, newData); 
    } 
    const updateEntity = async(updatedData: StaffAndVesselCodeEntity): Promise<ResponseEntity> => { 
        return await axiosPutData(referenceAxiosInstance, `${url}`, updatedData); 
    } 
    const deleteEntity= async(keys: number[]): Promise<ResponseEntity> => { 
        const delurl = '/v1/staffAndVesselCodeBatchDelete'
        return await axiosPostData(referenceAxiosInstance, `${delurl}`, keys); 
    } 
    return { 
        getEntities: getEntities, 
        getEntityByKey: getEntityByKey, 
        createEntity: createEntity, 
        updateEntity: updateEntity, 
        deleteEntity: deleteEntity, 
    } 
} 
