import { billingTypeForSOARadioOptions } from "presentation/constant/RadioOptions/StaticRadioOptions";
import { StaffAndVesselCodeMaintenanceConstant } from "presentation/constant/StaffAndVesselCode/StaffAndVesselCodeMaintenanceConstant";
import { useStaffAndVesselCodeMaintenanceVM } from "presentation/hook/StaffAndVesselCode/useStaffAndVesselCodeMaintenanceVM";
import { useStaffAndVesselCodeMaintenanceTracked } from "presentation/store/StaffAndVesselCode/StaffAndVesselCodeMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useEffect, useMemo } from "react";
import { FieldType, HPHCheckbox, IFieldValue } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, SidebarTitle, StyledSidebar } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import StaffAndVesselCodeEditPanelTitleBar from "./StaffAndVesselCodeEditPanelTitleBar";
export const StaffAndVesselCodeMaintenanceFormPanel = () => { 
    const [staffAndVesselCodeState] = useStaffAndVesselCodeMaintenanceTracked(); 
    const staffAndVesselCodeVM = useStaffAndVesselCodeMaintenanceVM(); 
    const {currentSelectedRow, masterState, dynamicOptions} = staffAndVesselCodeState; 
    const {editingEntity, isAdd, isEditable, isRead, isSaveClicked, allFormState} = masterState; 
    const SCREEN_CONSTANT = StaffAndVesselCodeMaintenanceConstant; 

    const currentEntity = isRead? currentSelectedRow : editingEntity; 


    useEffect(() => {
        const refreshValueCode = async()=>{

            currentEntity.billingType&&staffAndVesselCodeVM.refreshValueCode(currentEntity.billingType);
        }    
        if(currentEntity.billingType){
            refreshValueCode();
        }
            
    },[currentEntity.billingType, staffAndVesselCodeVM])


    const memoUser = useMemo(() => 
        <div className='im-flex-row-item' style={{ width: "400px", marginBottom: "24px" }}> 
            <NbisCommonField 
                errorMessages={allFormState} 
                isReadOnly={isRead} 
                isShowOptional={false} 
                readOnlyValue={currentEntity?.staffCode || ''} 
                fieldValue={currentEntity?.staffCode || ''} 
                fieldLabel={SCREEN_CONSTANT.LOGIN_USER} 
                isSaveClicked={isSaveClicked} 
                fieldType={FieldType.DROPDOWN} 
                fieldKey={'staffCode'} 
                isShowMissingError={true} 
                maxLength={60} 
                options={dynamicOptions.loginUserDropdownOption}
                requiredFieldList={[]} 
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                    staffAndVesselCodeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
        </div> 
        , [allFormState, isRead, currentEntity?.staffCode, SCREEN_CONSTANT.LOGIN_USER, isSaveClicked, dynamicOptions.loginUserDropdownOption, staffAndVesselCodeVM]) 
        const memoSoa = useMemo(() => 
            <div className='im-flex-row-item' style={{ width: "400px", marginBottom: "24px" }}> 

                <NbisCommonField 
                    errorMessages={allFormState} 
                    isReadOnly={currentEntity?.billingType==="DOMESTIC"} 
                    isShowOptional={true} 
                    readOnlyValue={currentEntity?.vesselCode || ''} 
                    fieldValue={currentEntity?.vesselCode} 
                    fieldLabel={SCREEN_CONSTANT.SOA} 
                    isSaveClicked={isSaveClicked} 
                    fieldType={FieldType.TEXT} 
                    fieldKey={'vesselCode'} 
                    isShowMissingError={true} 
                    maxLength={60} 
                    requiredFieldList={[]} 
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                        staffAndVesselCodeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />         
            </div> 
            , [allFormState, currentEntity?.billingType, currentEntity?.vesselCode, SCREEN_CONSTANT.SOA, isSaveClicked, staffAndVesselCodeVM]) 
        const memoBillTo = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"400px", marginBottom:"24px"}}> 
                <NbisCommonField 
                    errorMessages={allFormState} 
                    isReadOnly={currentEntity?.billingType!=="DOMESTIC"} 
                    isShowOptional={true} 
                    readOnlyValue={currentEntity?.billToCompany || ''} 
                    fieldValue={currentEntity?.billToCompany || ''} 
                    fieldLabel={SCREEN_CONSTANT.BILL_TO} 
                    isSaveClicked={isSaveClicked} 
                    fieldType={FieldType.DROPDOWN} 
                    fieldKey={'billToCompany'} 
                    isShowMissingError={true} 
                    maxLength={60} 
                    options={dynamicOptions.billToCompanyDropdownOptions}
                    requiredFieldList={[]} 
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                        staffAndVesselCodeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />   
            </div> 
        , [allFormState, currentEntity?.billingType, currentEntity?.billToCompany, SCREEN_CONSTANT.BILL_TO, isSaveClicked, dynamicOptions.billToCompanyDropdownOptions, staffAndVesselCodeVM]) 
        const memoServCode = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"400px", marginBottom:"24px"}}> 
                <NbisCommonField 
                    errorMessages={allFormState} 
                    isReadOnly={currentEntity?.billingType==="DOMESTIC"} 
                    isShowOptional={true} 
                    readOnlyValue={currentEntity?.serviceCode || ''} 
                    fieldValue={currentEntity?.serviceCode || ''} 
                    fieldLabel={SCREEN_CONSTANT.SERV_CODE} 
                    isSaveClicked={isSaveClicked} 
                    fieldType={FieldType.DROPDOWN} 
                    fieldKey={'serviceCode'} 
                    isShowMissingError={true} 
                    maxLength={60} 
                    options={dynamicOptions.serviceCodeDropdownOptions}
                    requiredFieldList={[]} 
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                        staffAndVesselCodeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />   
            </div> 
        , [allFormState, currentEntity?.billingType, currentEntity?.serviceCode, SCREEN_CONSTANT.SERV_CODE, isSaveClicked, dynamicOptions.serviceCodeDropdownOptions, staffAndVesselCodeVM]) 
        
        const memoBillType = useMemo(() => 
            <div className='im-flex-row-item' style={{width:"400px", marginBottom:"24px"}}> 
                <NbisCommonField 
                    errorMessages={allFormState} 
                    isReadOnly={isRead} 
                    isShowOptional={false} 
                    readOnlyValue={currentEntity?.billingType || ''} 
                    fieldValue={currentEntity?.billingType} 
                    fieldLabel={SCREEN_CONSTANT.BILLING_TYPE} 
                    isSaveClicked={isSaveClicked} 
                    fieldType={FieldType.RADIO_GROUP} 
                    fieldKey={'billingType'} 
                    isShowMissingError={true} 
                    maxLength={60} 
                    options={billingTypeForSOARadioOptions}
                    requiredFieldList={[]} 
                    onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>  
                        staffAndVesselCodeVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} /> 

            </div> 
        , [SCREEN_CONSTANT.BILLING_TYPE, allFormState, currentEntity?.billingType, isRead, isSaveClicked, staffAndVesselCodeVM]) 

        const memoUserInCharge = useMemo(() => 
            <> 
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}> 

                <NbisCommonField 
                    fieldKey={"userInChargeInd"} 
                    isShowOptional={true} 
                    requiredFieldList={[]} 
                    fieldLabel={SCREEN_CONSTANT.USER_IN_CHARGE}  
                    fieldType={FieldType.TEXT}  
                    isReadOnly={true} 
                    onFieldChange={()=>{}}/> 
                <HPHCheckbox 
                    label={""} 
                    checked={currentEntity?.userInChargeInd==='Y'} 
                    disabled={isRead} 
                    onChange={(e) => staffAndVesselCodeVM.onCheckboxChange(e.checked, "userInChargeInd")} 
                /> 
            </div> 
            </> 
        , [SCREEN_CONSTANT.USER_IN_CHARGE, currentEntity?.userInChargeInd, isRead, staffAndVesselCodeVM]) 

        const memoActiveInd = useMemo(() => 
            <> 
                <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}> 

                <NbisCommonField 
                    fieldKey={"activeInd"} 
                    requiredFieldList={[]} 
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}  
                    fieldType={FieldType.TEXT}  
                    isReadOnly={true} 
                    onFieldChange={()=>{}}/> 
                <HPHCheckbox 
                    label={""} 
                    checked={currentEntity?.activeInd==='Y'} 
                    disabled={isRead} 
                    onChange={(e) => staffAndVesselCodeVM.onCheckboxChange(e.checked, "activeInd")} 
                /> 
            </div> 
            </> 
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, staffAndVesselCodeVM]) 

    const memoEditPanelTitleBar = useMemo(() => { 
        return <StaffAndVesselCodeEditPanelTitleBar/> 
    }, []) 
    return <StyledSidebar style={{ width: '100%', height: "100%", border: 'none', backgroundColor: '#FFFFFF', overflow: 'auto', padding: '0' }} position='right'> 
        {/* Header part of add or edit record */} 
        {memoEditPanelTitleBar} 
        {/* Readable and Editable Form */} 
        <div className={'add-edit-form'} style={{ height: 'calc(100vh - 125px)', maxHeight: 'calc(100vh - 125px)', overflow: 'auto' }}> 
                <div className="flex-row-auto"> 
                    <div className={'flex-row-item'}> 
                        <div className={'im-flex-row'}> 
                            <div className={'flex-row-item-stretch'}> 
                                <SidebarTitle>{ currentEntity.staffCode ?? "NEW USER"}</SidebarTitle> 
                                {(isAdd || (isEditable && isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>} 
                            </div> 
                        </div> 
                        <CriteriaItemContainer> 
                            {memoBillType} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoUser} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {currentEntity?.billingType !=="DOMESTIC" ? memoSoa : <></>} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {currentEntity?.billingType ==="DOMESTIC"?memoBillTo : <></>} 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {currentEntity?.billingType !=="DOMESTIC" ? memoServCode : <></> } 
                        </CriteriaItemContainer> 
                        <CriteriaItemContainer> 
                            {memoUserInCharge} {memoActiveInd}
                        </CriteriaItemContainer> 
                    </div> 
                </div> 
            </div> 
        {/* Readable and Editable Form */} 
    </StyledSidebar> 
} 
